{
  "appname": "Motivational Quotes",
  "deletionAppName": "Motivational Quotes App",
  "mail": "{'support@motivationalquotes.me'}",
  "getTheApp": "Hol dir die Motivational Quotes App!",
  "home-header": "Die besten Sprüche!",
  "home-subheader": "Schicke Liebe, Motivation, Glückwünsche & Weisheiten für jede Lebenslage in WhatsApp, auf Instagram, Facebook Messenger und auf allen anderen Plattformen.",
  "dataprotection_header_web": "Datenschutzerklärung von Motivational Quotes (Web Version)",
  "dataprotection_header_app": "Datenschutzerklärung von Motivational Quotes App"
}
