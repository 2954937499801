{
  "appname": "Motivational Quotes",
  "deletionAppName": "Motivational Quotes App",
  "mail": "{'support@motivationalquotes.me'}",
  "getTheApp": "Get the Motivational Quotes App!",
  "home-header": "The best greetings!",
   "home-subheader": "Send quotes about Love, Motivation, Wishes & Greetings for every occasion in WhatsApp, Instagram, Facebook Messenger and every other platform.",
  "dataprotection_header_web": "Privacy Policy of Motivational Quotes (Web Version)",
  "dataprotection_header_app": "Privacy Policy of Motivational Quotes App"
}
